import * as Yup from 'yup';

export const createAccountValidationSchema = Yup.object().shape({
  dateOfBirth: Yup.string().required('Field is required'),
  maritalStatus: Yup.string().required('Please select an option'),
  gender: Yup.string().required('Please select an option'),
  phoneNumber: Yup.string()
    .required('Field is required')
    .matches(
      /^0/,
      'The phone number you have entered is incorrect. Phone number format 08012345678',
    )
    .length(11, 'Phone number must be 11 digits'),
  email: Yup.string()
    .required('Field is required')
    .email('Please enter a valid email address'),
  password: Yup.string()
    .required('Field is required')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{6,20})/,
      'Password must be at least 6 characters and must contain a digit, a lowercase character and an uppercase character',
    ),
  confirmPassword: Yup.string()
    .required('Field is required')
    .equals(
      [Yup.ref('password')],
      'Passwords do not match. Please check your password',
    ),
  termsandagreement: Yup.boolean().oneOf(
    [true],
    'To continue, please, agree to the terms and conditions of this loan.',
  ),
  privacyPolicy: Yup.boolean().oneOf(
    [true],
    'To continue, please, agree to the Privacy Policy.',
  ),
  residentialAddress: Yup.string()
    .required('Field is required')
    .min(10, 'Address must be at least 10 characters.'),
  residentialState: Yup.string().required('Field is required'),
  localGovernment: Yup.string().required('Field is required'),
});

export const LoanGroupValidationSchema = Yup.object().shape({
  loanProduct: Yup.string().required('Please select a loan product'),
});

export const OTPValidationSchema = Yup.object().shape({
  otp: Yup.string()
    .required('Field is required')
    .length(6, 'OTP must be 6 digits'),
});

export const EmailOTPValidationSchema = Yup.object().shape({
  workEmailVerificationCode: Yup.string()
    .required('Field is required')
    .length(6, 'OTP must be 6 digits'),
});

export const EditPhoneNumberValidationSchema = Yup.object().shape({
  phoneNumber: Yup.string()
    .required('Field is required')
    .matches(
      /^0/,
      'The phone number you have entered is incorrect. Phone number format 08012345678',
    )
    .length(11, 'Phone number must be 11 digits'),
});

export const EditWorkEmailValidationSchema = Yup.object().shape({
  workEmail: Yup.string()
    .required('Field is required')
    .email('Please enter a valid work email'),
});

export const EditEmailValidationSchema = Yup.object().shape({
  email: Yup.string()
    .required('Field is required')
    .email('Please enter a valid email'),
});

export const BVNPhoneValidationSchema = Yup.object().shape({
  bvnPhoneDigits: Yup.string()
    .required('Field is required')
    .length(4, 'Phone digits must be 4 digits'),
});

export const GovernmentIdCardSchema = Yup.object().shape({
  documentName: Yup.string().required('Field is required'),
  documentNo: Yup.string().required('Field is required'),
});

export const AddressDetailsSchema = Yup.object().shape({
  address: Yup.string()
    .required('Field is required')
    .min(10, 'Address must be at least 10 characters.'),
  state: Yup.string().required('Field is required'),
  localGovernment: Yup.string().required('Field is required'),
});

export const employedSchema = Yup.object().shape({
  employerName: Yup.string().required('Field is required'),
  employerAddress: Yup.string().required('Field is required'),
  employerState: Yup.string().required('Field is required'),
  jobRole: Yup.string().required('Field is required'),
  netIncome: Yup.string().required('Field is required'),
  workStartDate: Yup.string().required('Field is required'),
  workEmail: Yup.string().email('Please enter a valid work email'),
});

export const selfEmployedSchema = Yup.object().shape({
  employerName: Yup.string().required('Field is required'),
  employerAddress: Yup.string().required('Field is required'),
  employerState: Yup.string().required('Field is required'),
  netIncome: Yup.string().required('Field is required'),
});

export const facebookProfileUrlSchema = Yup.object().shape({
  facebookProfileUrl: Yup.string()
    .required('Field is required')
    .matches(
      /(?:https?:\/\/)?(?:www\.)?facebook\.com\/.(?:(?:\w)*#!\/)?(?:pages\/)?(?:[\w]*\/)*([\w]*)/,
      'Please enter a valid facebook profile url',
    ),
});

export const refereeDetailsSchema = Yup.object().shape({
  refereeName: Yup.string()
    .required('Field is required')
    .min(5, 'Name must be at least 5 characters.'),
  refereePhone: Yup.string()
    .required('Field is required')
    .matches(
      /^0/,
      'The phone number you have entered is incorrect. Phone number format 08012345678',
    )
    .length(11, 'Phone number must be ${validation.PHONE_LENGTH} digits'), // eslint-disable-line
  refereeWorkPlace: Yup.string()
    .required('Field is required')
    .min(10, 'Address must be at least 10 characters.'),
});

export const loanTenorSchema = Yup.object().shape({
  loanDuration: Yup.string().required('Field is required'),
});

export const MbsOTPSchema = Yup.object().shape({
  ticketNum: Yup.string(),
  password: Yup.string(),
});

export const ExternalMbsOTPSchema = Yup.object().shape({
  ticketNum: Yup.string().required(
    'Please, enter the Ticket Number sent by your bank',
  ),
  password: Yup.string().required(
    'Please, enter OTP/Password sent by your bank',
  ),
});

export const bankDetailsSchema = Yup.object().shape({
  accountNumber: Yup.string()
    .required('Field is required')
    .length(10, 'Account number must be 10 digits'),
  bankId: Yup.string().when('bankName', {
    is: value => {
      return value == null;
    },
    then: Yup.string().required('Field is required'),
    otherwise: Yup.string().notRequired(),
  }),
  accountType: Yup.string().required('Field is required'),
});
