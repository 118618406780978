import React, { useMemo, useState } from 'react';
import * as S from './styled';
import { DataCard, Popup } from '../../../components';
import { Wrapper, Title } from '../styled';
import { getLoanPolicy } from '../../../lib/utils';
import moment from 'moment';
import useConfirmRequest from './useConfirmRequest';
import { NairaAmount } from '../../../components/Icon';

const ConfirmRequest = () => {
  const {
    applicationLoading,
    data,
    offerLoading,
    onComplete,
    completeApplicationLoading,
    application,
  } = useConfirmRequest();

  const [showRepaymentBreakdown, togglePopup] = useState(false);

  const { generateApplicationOffers } = data || {};
  const { suggestedOffer } = generateApplicationOffers || {};
  const { dateOfRepayment } = application?.application || {};
  const { data: suggestedOfferData, policy: suggestedPolicy } =
    suggestedOffer || {};

  const { processingFee, processingFeeDebitMethod } = suggestedPolicy || {};
  const { repaymentBreakdown } = suggestedOfferData || {};

  const policy = useMemo(() => {
    return suggestedOfferData ? getLoanPolicy(suggestedOfferData) : {};
  }, [suggestedOfferData]);

  const rows = useMemo(() => {
    let tableRows = [];
    let totalPrincipal = 0;
    let totalInterest = 0;
    let totalPayment = 0;

    tableRows = repaymentBreakdown?.map((repayment, index) => {
      totalPrincipal += repayment?.principalPortion;
      totalInterest += repayment?.interestPortion;
      totalPayment += repayment?.expectedPayment;
      return (
        <S.TableRow key={`repayment_${index}`}>
          <S.TableData>{moment(repayment?.dueDate).format('ll')}</S.TableData>
          <S.TableData>
            <NairaAmount amount={repayment?.principalPortion} />
          </S.TableData>
          <S.TableData>
            <NairaAmount amount={repayment?.interestPortion} />
          </S.TableData>
          <S.TableData>
            <NairaAmount amount={repayment?.expectedPayment} />
          </S.TableData>
        </S.TableRow>
      );
    });
    tableRows?.push(
      <S.TableRow key={`repayment_total`}>
        <S.TableData>
          <b>Total</b>
          <span>Amount</span>
        </S.TableData>
        <S.TableData>
          <b>
            <NairaAmount amount={totalPrincipal} />
          </b>
        </S.TableData>
        <S.TableData>
          <b>
            <NairaAmount amount={totalInterest} />
          </b>
        </S.TableData>
        <S.TableData>
          <b>
            <NairaAmount amount={totalPayment} />
          </b>
        </S.TableData>
      </S.TableRow>,
    );
    return tableRows;
  }, [repaymentBreakdown]);

  return (
    <Wrapper>
      <DataCard
        loading={
          applicationLoading || offerLoading || completeApplicationLoading
        }
      >
        <Title>Confirm your offer</Title>
        <S.Summary>
          <S.Detail>
            <p>Requested Amount</p>
            <p>
              <NairaAmount amount={suggestedOfferData?.baseAmount} />
            </p>
          </S.Detail>
          <S.Detail>
            <p>Repayment Amount</p>
            <p>
              <NairaAmount amount={suggestedOfferData?.fullAmount} />
            </p>
          </S.Detail>
          <S.Detail>
            <p>Repayment Date</p>
            <p>{moment(dateOfRepayment)?.format('ll')}</p>
          </S.Detail>
          <S.Detail>
            <p>Interest Rate</p>
            <p>{`${
              suggestedPolicy?.interestRate?.value || policy?.interestRate
            }% ${policy?.repaymentType}`}</p>
          </S.Detail>
          <S.Detail>
            <p>Loan Tenor</p>
            <p>{policy?.loanTenor}</p>
          </S.Detail>
          {processingFee?.value > 0 && (
            <S.Detail>
              <p>Processing Fee</p>
              <p>
                {processingFee?.calcBy === 'PERCENTAGE' ? (
                  `${processingFee?.value}%`
                ) : (
                  <NairaAmount amount={processingFee?.value} />
                )}
              </p>
            </S.Detail>
          )}
        </S.Summary>
        <S.ViewRepaymentButton onClick={() => togglePopup(true)}>
          View Repayment Details
        </S.ViewRepaymentButton>

        {processingFee?.value > 0 && (
          <S.ProcessingFeeInfo>
            If your loan is approved, a processing fee of{' '}
            {processingFee?.calcBy === 'PERCENTAGE' ? (
              `${processingFee?.value}%`
            ) : (
              <NairaAmount amount={processingFee?.value} />
            )}{' '}
            will be deducted from the loan amount{' '}
            {processingFeeDebitMethod === 'POST_DISBURSEMENT'
              ? 'after'
              : 'before'}{' '}
            credit to your bank account.
          </S.ProcessingFeeInfo>
        )}

        <S.NextButton onClick={onComplete} value="Next" />
        {showRepaymentBreakdown && (
          <S.Modal>
            <Popup title="Repayment Breakdown">
              <S.CloseIcon onClick={() => togglePopup(false)} />
              <S.Table>
                <S.TableHeader>
                  <S.TableRow>
                    <S.TableHeading span="col">Repayment Date</S.TableHeading>
                    <S.TableHeading span="col">Principal</S.TableHeading>
                    <S.TableHeading span="col">Interest</S.TableHeading>
                    <S.TableHeading span="col">Total Payment</S.TableHeading>
                  </S.TableRow>
                </S.TableHeader>
                <S.TableBody>{rows}</S.TableBody>
              </S.Table>
              <S.ContinueButton onClick={() => togglePopup(false)}>
                Continue
              </S.ContinueButton>
            </Popup>
          </S.Modal>
        )}
      </DataCard>
    </Wrapper>
  );
};

export default ConfirmRequest;
